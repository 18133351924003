
import { defineComponent } from 'vue';
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'MensagemSemDados',
  props: {
    mensagem: {
      type: String,
      default: 'Nenhum dado foi adicionada ainda',
    },
    mensagemAdicional: {
      type: String,
      default: '',
    },
    resultadoPesquisa: {
      type: Boolean,
      default: false,
    },
    adicionarDado: {
      type: Boolean,
      default: false,
    },
    tamanhoImagem: {
      type: Number,
      default: 200,
    },
  },
  setup(props) {
    return {
      props,
      Empty,
    };
  },
});
