import ApiCommerce from '@/core/apis/ApiCommerce';
import { ICaracteristica } from '@/models/Entidades/ICaracteristica';
import { IResposta } from '@/core/models/IResposta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IRepostaConsulta } from '@/core/models/IRepostaConsulta';
import { ICaracteristicaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/ICaracteristicaParametrosConsulta';
import { IDTOCaracteristica } from '@/models/DTO/IDTOCaracteristica';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoDefinicaoVariacao } from '@/models/Entidades/IProduto';
import { ETipoCaracteristica } from '@/models/Enumeradores/ETipoCaracteristica';
/**
 * Serviço de Caracteristicas
 */

class ServicoCaracteristica implements IServicoBase<ICaracteristica> {
  endPoint = 'caracteristicas';

  apiCommerce = new ApiCommerce();

  public async incluir(caracteristica: ICaracteristica): Promise<IResposta> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}`, caracteristica);
    return result.data;
  }

  public async atualizar(caracteristica: ICaracteristica): Promise<IResposta> {
    const result: any = await this.apiCommerce.put(`${this.endPoint}/${caracteristica.codigo}`, caracteristica);
    return result.data;
  }

  public async obter(codigo: number): Promise<ICaracteristica> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterTodas(): Promise<ICaracteristica[]> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/todas`);
    return result.data;
  }

  public async obterCaracteristicas(parametrosConsulta: ICaracteristicaParametrosConsulta): Promise<IRepostaConsulta<IDTOCaracteristica>> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.filtro !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.filtro}`;
    }

    if (parametrosConsulta.variacao !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.variacao}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaRapida(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
    const parametros = this.apiCommerce.obterParametrosBuscaRapida(parametrosConsultaRapida);
    const result: any = await this.apiCommerce.get(`${this.endPoint}/busca-rapida${parametros}`);
    return result.data;
  }

  public async buscaRapidaCaracteristicaItem(parametrosConsultaRapida: IParametrosBuscaRapida, codigoCaracteristica: number, subItens? :boolean): Promise<IItemBuscaRapida[]> {
    let parametros = this.apiCommerce.obterParametrosBuscaRapida(parametrosConsultaRapida);

    parametros += (parametros !== '' ? '&' : '?');
    parametros += `CodigoCaracteristica=${codigoCaracteristica}`;

    if (subItens !== undefined && UtilitarioGeral.validaBoleano(subItens)) {
      parametros += (parametros !== '' ? '&' : '?');
      parametros += `SubItens=${subItens}`;
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}/busca-rapida-itens${parametros}`);
    return result.data;
  }

  public async deletar(codigo: number): Promise<IResposta> {
    const result: any = await this.apiCommerce.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public montaVariacaoProduto(produtoCaracteristicas: IProdutoDefinicaoVariacao[], caracteristicas: ICaracteristica[]):string {
    let descricaoVariacao = '';
    if (UtilitarioGeral.validaLista(produtoCaracteristicas) && UtilitarioGeral.validaLista(caracteristicas)) {
      produtoCaracteristicas.forEach((produtoCaracteristica) => {
        const dadosCaracteristica = caracteristicas.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristica);
        if (dadosCaracteristica !== undefined) {
          if (dadosCaracteristica.tipo === ETipoCaracteristica.Texto
              || dadosCaracteristica.tipo === ETipoCaracteristica.Decimal
              || dadosCaracteristica.tipo === ETipoCaracteristica.Numero) {
            descricaoVariacao += ` ${dadosCaracteristica.nome}: ${produtoCaracteristica.valor}`;
          } else if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSimples
              || dadosCaracteristica.tipo === ETipoCaracteristica.Cores) {
            const dadosItemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
            if (dadosItemCaracteristica !== undefined) {
              descricaoVariacao += ` ${dadosCaracteristica.nome}: ${dadosItemCaracteristica.valor}`;
            }
          } else if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
            const dadosItemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
            if (dadosItemCaracteristica !== undefined) {
              const dadosSubItemCaracteristica = dadosItemCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
              if (dadosSubItemCaracteristica !== undefined) {
                descricaoVariacao += ` ${dadosCaracteristica.descricao}: ${dadosSubItemCaracteristica.valor}`;
              }
            }
          }
        }
      });
    }
    return descricaoVariacao;
  }
}

export default ServicoCaracteristica;
