import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ss-texto-centralizado" }
const _hoisted_2 = ["src", "width"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ss-texto-centralizado" }
const _hoisted_5 = ["src", "width"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.props.resultadoPesquisa?'/static/img/animacoes/resultado-pesquisa.gif':'',
        width: _ctx.props.tamanhoImagem,
        alt: "Resultado"
      }, null, 8, _hoisted_2),
      _createElementVNode("h3", null, _toDisplayString(_ctx.props.mensagem), 1),
      (_ctx.props.mensagemAdicional)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.props.mensagemAdicional), 1))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.props.resultadoPesquisa]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        src: _ctx.props.adicionarDado?'/static/img/animacoes/novo-registro.gif':'',
        width: _ctx.props.tamanhoImagem,
        alt: "Mensagem"
      }, null, 8, _hoisted_5),
      _createElementVNode("h3", null, _toDisplayString(_ctx.props.mensagem), 1),
      (_ctx.props.mensagemAdicional)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.props.mensagemAdicional), 1))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.props.adicionarDado]
    ]),
    (!_ctx.props.resultadoPesquisa && !_ctx.props.adicionarDado)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_a_empty, {
            image: _ctx.Empty.PRESENTED_IMAGE_SIMPLE
          }, {
            description: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.props.mensagem), 1)
            ]),
            _: 1
          }, 8, ["image"])
        ]))
      : _createCommentVNode("", true)
  ]))
}